<template>
    <v-container class="pa-5">
    <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
        <v-icon>mdi-new-box</v-icon>
        Create New ORMP Row 
    </div>
    <v-stepper
      v-model="e6"
      vertical
    >
      <v-stepper-step
        :complete="e6 > 1"
        step="1"
      >
        Risk Name
      </v-stepper-step>
       <v-stepper-content step="1">
         {{hello}}
         
         <v-select
            :items="RiskClassification"
            item-text="RiskClassification"
            item-value='.key'
            label="Select Risk Classification"
            outlined
            v-model="selectedRiskClassification"
            return-object
          ></v-select>
          <v-select
            :items="cascadeRiskName"
            item-text="RiskName"
            item-value="id"
            label="Select Risk Name"
            v-model="selectedRisk"
            no-data-text="Please select a Risk Classification first"
            outlined
          ></v-select>
          <v-icon v-show="this.selectedRisk">mdi-information-outline </v-icon><span v-show="this.selectedRisk"> Info</span>
          <v-row>
              <v-col>
                <v-list>
                  <v-list-item-title v-show="this.selectedRisk"><h3>Risk Universe</h3></v-list-item-title>
                    <!-- <v-list-item>{{riskNameDetails('RiskName')}}</v-list-item> -->
                </v-list>   
              </v-col>

              <v-col>
                <v-list>
                  <v-list-item-title v-show="this.selectedRisk"><h3>Universal Causes</h3></v-list-item-title>
                    <!-- <v-list-item
                    v-for="(line) in riskNameDetails('UniversalCauses')" 
                    :key="line"
                    >• {{line}}
                  </v-list-item> -->
                </v-list>
              </v-col>

              <v-col>
                <v-list>
                  <v-list-item-title v-show="this.selectedRisk"><h3>Universal Impacts</h3></v-list-item-title>
                  <!-- <v-list-item
                  v-for="(line) in riskNameDetails('UniversalImpacts')" 
                  :key="line"
                  >
                  • {{line}}
                  </v-list-item> -->
                </v-list>  
              </v-col>

              <v-col>
                <v-list>
                  <v-list-item-title v-show="this.selectedRisk"><h3>Universal Treatments</h3></v-list-item-title>
                  <!-- <v-list-item
                  v-for="(line) in riskNameDetails('UniversalTreatments')" 
                  :key="line"
                  >
                  • {{line}}
                  </v-list-item> -->
                </v-list>  
              </v-col>
          </v-row>
          
        <v-btn
          color="primary"
          @click="e6 += 1"
        >
          Continue
        </v-btn>
        <v-btn text>
          Cancel
        </v-btn>
      </v-stepper-content>
   
      <v-stepper-step
        :complete="e6 > 2"
        step="2"
      >
        Objective
      </v-stepper-step>
      <v-stepper-content step="2">
        <!-- {{ORMP.RiskName}}
        {{mapInputArray(ORMP.Causes)}} -->
        <v-btn
        @click="addNewField('objectiveInputFields')"
        class="float-right mr-1 add-button-position"
        fab
        small
        color="primary"
        >
        <v-icon dark>
        mdi-plus
        </v-icon>
        </v-btn>

        <v-text-field 
          v-for="(field, index) in this.objectiveInputFields" 
          :key="index" 
          :placeholder="index !== 0 ? 'Input another Objective' : 'Input an Objective'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'objectiveInputFields')"
          >
        </v-text-field>
        
        <v-btn
          color="primary"
          @click="e6 += 1"
        >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Cancel
        </v-btn>
        
      </v-stepper-content>
  
      <v-stepper-step
        :complete="e6 > 3"
        step="3"
      >
        Causes - Definition
      </v-stepper-step>
      <v-stepper-content step="3">
         <v-btn
          @click="addNewField('causesInputFields')"
          class="float-right mr-1 add-button-position"
          fab
          small
          color="primary"
          >
          <v-icon dark>
            mdi-plus
          </v-icon>
          </v-btn>

        <v-text-field 
          v-for="(field, index) in this.causesInputFields" 
          :key="index" 
          :placeholder="index !== 0 ? 'Input another Cause' : 'Input a Cause'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'causesInputFields')"
          >
        
        </v-text-field>
            <v-col>
                <v-list>
                  <v-list-item-title v-if="this.selectedRisk"><h3>Universal Causes</h3></v-list-item-title>
                    <!-- <v-list-item
                    v-for="(line) in riskNameDetails('UniversalCauses')" 
                    :key="line"
                    >• {{line}}
                  </v-list-item> -->
                </v-list>
              </v-col>        
        <v-btn
          color="primary"
           @click="e6 += 1"
        >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Cancel
        </v-btn>
      </v-stepper-content>
  
      <v-stepper-step 
      :complete="e6 > 4"
      step="4">
        Impact - Definition
      </v-stepper-step>
      <v-stepper-content step="4">
         <v-btn
          @click="addNewField('impactsInputFields')"
          class="float-right mr-1 add-button-position"
          fab
          small
          color="primary"
          >
          <v-icon dark>
            mdi-plus
          </v-icon>
          </v-btn>
        <v-text-field 
          v-for="(field, index) in this.impactsInputFields" 
          :key="index" 
          :placeholder="index !== 0 ? 'Input another Impact' : 'Input an Impact'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'impactsInputFields')"
          >
        </v-text-field>
        <v-col>
            <!-- <v-list>
                <v-list-item-title v-if="this.selectedRisk"><h3>Universal Impacts</h3></v-list-item-title>
                <v-list-item
                v-for="(line) in riskNameDetails('UniversalImpacts')" 
                :key="line"
                >
                • {{line}}
                </v-list-item>
            </v-list>   -->
        </v-col>
        <v-btn
          color="primary"
          @click="e6 += 1"
        >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Cancel
        </v-btn>
      </v-stepper-content>
       <v-stepper-step 
       :complete="e6 > 5"
       step="5">
       Affected Stakeholders
       </v-stepper-step>
       <v-stepper-content step="5">
         <v-btn
          @click="addNewField('selectedStakeholders')"
          class="float-right ml-3 mt-2 add-button-position"
          fab
          small
          color="primary"
          >
           <v-icon dark>
            mdi-plus
          </v-icon>
          </v-btn>
          <div v-for="(field, index) in this.selectedStakeholders" :key="index">
            <v-btn
            @click="deleteObjectiveInput(index, 'selectedStakeholders')"
            v-if="index !== 0 ? 'mdi mdi-delete' : ''"
            class="float-right ml-3 mt-2 add-button-position"
            fab
            small
            color="primary"
            >
           <v-icon dark>
            mdi-delete
            </v-icon>
            </v-btn>
            <v-select
            :items="AffectedStakeholder"
            item-text="AffectedStakeholder"
            item-value="AffectedStakeholder"
            label="Select Affected Stakeholder"
            outlined
            v-model="field.text"
            ></v-select>

          </div>
        <v-btn
          color="primary"
          @click="e6 += 1"
        >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Cancel
        </v-btn>
      </v-stepper-content>
        <v-stepper-step 
        :complete="e6 > 6"
        step="6">
        Inherent Risk Rating
      </v-stepper-step>
        <v-stepper-content step="6">
          <v-row>
            <v-col class="mt-5">
              <v-select
              :items="LikelihoodLevel"
              item-text="Level"
              item-value=".key"
              label="Select Likelihood Level"
              outlined
              v-model="selectedLikelihood"
              >
              </v-select>
            </v-col>
             <v-col>
              <v-list>
                  <v-list-item v-if="this.selectedLikelihood"><h3>Frequency</h3></v-list-item>
                  <v-list-item>
                  <!-- {{displayRiskRating('Frequency')}} -->
                  </v-list-item>
                </v-list>  
            </v-col>

            <v-col>
              <v-list>
                  <v-list-item v-if="this.selectedLikelihood"><h3>Probability</h3></v-list-item>
                  <v-list-item>
                  <!-- {{displayRiskRating('Probability')}} -->
                  
                  </v-list-item>
                </v-list>  
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
              :items="RiskImpactLevel"
              item-text="Level"
              item-value=".key"
              label="Select Impact Criteria Level"
              outlined
              v-model="selectedImpactLevel"
              >
              </v-select>
            </v-col>

            <v-col>
              <v-select
              :items="ImpactArr"
              item-text="name"
              item-value="id"
              label="Select Impact Classification"
              outlined
              v-model="selectedImpactClassification"
              >
              </v-select>
            </v-col>

            <v-col>
              <!-- <v-list>
                <v-list-item 
                v-for="(impact, index) in this.displayImpact"
                :key="index" 
                >{{impact}}</v-list-item>
              </v-list>
               -->
       
              
            </v-col>
            <v-col>
               <v-list v-if="this.selectedLikelihood && this.selectedImpactLevel">
                  <v-list-item><h3>Vulnerability Rating</h3></v-list-item>
                  <v-list-item>
                  <!-- {{computeVulnerability}} -->
                  </v-list-item>
                </v-list> 
            </v-col>
          </v-row>
         
        <v-btn
          color="primary"
          @click="e6 += 1"
        >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Cancel
        </v-btn>
      </v-stepper-content>

      <v-stepper-step 
        :complete="e6 > 7"
        step="7">
        Risk Strategy
      </v-stepper-step>
      <v-stepper-content step="7">
          <v-select
              :items="RiskStrategy"
              item-text="RiskStrategy"
              item-value=".key"
              label="Select Risk Strategy"
              outlined
              v-model="selectedRiskStrategy"
              >
          </v-select>
          <v-btn
          color="primary"
           @click="e6 += 1"
          >
          Continue
          </v-btn>
        <v-btn @click="back(1)">
          Cancel
        </v-btn>
      </v-stepper-content>
      
      <v-stepper-step 
        :complete="e6 > 8"
        step="8">
        Risk Actions - Existing
      </v-stepper-step>
      <v-stepper-content step="8">
      <v-btn
      @click="addNewRiskAction"
      class="float-right ml-3 add-button-position"
      fab
      small
      color="primary"
      >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
      <div 
        v-for="(field, index) in this.riskActions" 
        :key="index" 
        class="mb-4"
        >
         <v-combobox
        v-model="field.causes"
        :items="mapCauses"
        item-text="text"
        item-value="value"
        chips
        clearable
        label="Select Causes"
        multiple
        prepend-icon="mdi-filter-variant"
        solo
      >
      <template v-slot:selection="{ attrs, item, select, selected }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        close
        @click="select"
        @click:close="remove(item,index)"
      >
        <strong>{{ item.text }}</strong>&nbsp;
          </v-chip>
        </template>
        </v-combobox>
         <v-text-field 
          :placeholder="index !== 0 ? 'Input another Risk Action' : 'Input a Risk Action'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'riskActions')"
          >
        </v-text-field>
    </div>

      
          <v-btn
          color="primary"
           @click="e6 += 1"
          >
          Continue
          </v-btn>
        <v-btn @click="back(1)">
          Cancel
        </v-btn>
      </v-stepper-content>
      <v-stepper-step 
      :complete="e6 > 9"
      step="9">
      Risk Treatment Owner
      </v-stepper-step>
      <v-stepper-content step="9">
         <v-btn
          @click="addNewField('selectedriskTreatmentOwners')"
          class="float-right ml-3 mt-2 add-button-position"
          fab
          small
          color="primary"
          >
           <v-icon dark>
            mdi-plus
          </v-icon>
          </v-btn>
          <div v-for="(field, index) in selectedriskTreatmentOwners" :key="index">
            <v-btn
            @click="deleteObjectiveInput(index, 'selectedriskTreatmentOwners')"
            v-if="index !== 0 ? 'mdi mdi-delete' : ''"
            class="float-right ml-3 mt-2 add-button-position"
            fab
            small
            color="primary"
            >
           <v-icon dark>
            mdi-delete
            </v-icon>
            </v-btn>
            <v-select
            :items="RiskTreatmentOwner"
            item-text="RiskTreatmentOwner"
            item-value="RiskTreatmentOwner"
            label="Select Risk Treatment Owner"
            outlined
            v-model="field.text"
            ></v-select>
          </div>
        <v-btn
          color="primary"
          @click="saveORMP()"
        >
          Save
        </v-btn>
        <v-btn @click="back(1)">
          Cancel
        </v-btn>
      </v-stepper-content>
    </v-stepper>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
            e6: 1,
            edited: {},
            ImpactArr: [{id: 'Environment', name: 'Environment  '},
            {id: 'HSS', name: 'Health, Safety & Security'},
            {id: 'LegalCompliance', name: 'Legal and Compliance'},
            {id: 'NIAT', name: 'Net Income After Tax'},
            {id: 'MGTACTION', name: 'Mgt Action'},
            {id: 'Operational', name: 'Operational'},
            {id: 'Reputation', name: 'Reputation'},
            ],
        }
    },

      beforeRouteEnter (to, from, next) {
      if(this.$db != undefined){
      this.$db.collection('ORMP').where("ORMP.key", '==', to.params.id).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next(vm => {
            vm.selectedRiskClassification = doc.data().RiskClassification
            vm.selectedRisk = doc.data().RiskName
          })
        })
      })
      }
      },
  
  
    watch: {
      '$route': 'fetchData'
    },
    props: ['id'],
    firestore(){
      return {
        ORMP: {
        // collection reference.
        ref: this.$db.collection('ORMP').doc(this.id),
          // Bind the collection as an object if you would like to.
        resolve: (data) => {
          // this.selectedRisk = data.RiskName
          this.selectedRiskClassification = data.RiskClassification
       
        },
        reject: (err) => {
          console.log(err)
          }
        },
     
        fetchData () {
        this.$db.collection('ORMP').where("ORMP.key", '==', this.$route.params.id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.selectedRiskClassification = doc.data().RiskClassification
            this.selectedRisk = doc.data().RiskName
          })
        })
      },
        // ORMP: this.$db.collection('ORMP').doc(this.id),
        Risk: this.$db.collection('Risk'),
        AffectedStakeholder: this.$db.collection('AffectedStakeholder'),
        RiskStrategy: this.$db.collection('RiskStrategy'),
        RiskTreatmentOwner: this.$db.collection('RiskTreatmentOwner'),
        LikelihoodLevel: this.$db.collection('LikelihoodLevel'),
        RiskImpactLevel: this.$db.collection('RiskImpactLevel'),
        RiskClassification: this.$db.collection('RiskClassification')
      }
    },

    created () {
      this.initialize()
    },

  
    methods: {

      initialize() {
        this.edited = {
            selectedRisk: '',
            selectedRiskClassification: '',
            selectedImpactClassification: '',
            selectedLikelihood: '',
            selectedImpactLevel: '',
            selectedImpactCriteria: '',
            selectedRiskStrategy: '',
            objectiveInputFields: [
              {
                text: ''
              }
            ],
            causesInputFields: [
              {
                text: ''
              }
            ],
            impactsInputFields: [
              {
                text: ''
              }
            ],
            selectedStakeholders: [
              {
                text:''
              }
            ],
            riskActions: [
              {
                text: '',
                causes: []
              }
            ],
            selectedriskTreatmentOwners: [
              {
                text: '',
              }
            ]
        }
      },

      remove (item,index) {
       this.riskActions[index].causes.splice(this.riskActions[index].causes.indexOf(item), 1)
      },
    
          back() {
            this.e6 -= 1;
          },
        
          computeVulnerabilityMethod(){
          let arr1 = this.LikelihoodLevel
          let arr2 = this.RiskImpactLevel
          let result
          
          let filter1 = arr1.filter(e => {
            return e['.key'] == this.selectedLikelihood
          })

          let filter2 = arr2.filter(e => {
            return e['.key'] == this.selectedImpactLevel
          })
            result = filter1[0].Level * filter2[0].Level
            return result
          },
          addNewRiskAction() {
            this.riskActions.push({text: ''})
          },
          displayRiskRating(criteria){
          let arr = this.LikelihoodLevel
          if(this.selectedLikelihood === ''){
              return ''
            } else {
              let filter = arr.filter(e => {
              return e['.key'] == this.selectedLikelihood
            })      
              return filter[0][criteria]
            }
          },
        deleteObjectiveInput(index, arrayName) {
            this[arrayName].splice(index, 1)
          },
          riskNameDetails(risk) {
            let i = this.selectedRisk
            let arr = this.Risk
            let filter = arr.filter(e => {
              return e['.key'] == this.selectedRisk
            })
            if(i === ''){
              return ''
            } else {
                return filter[0][risk]
            }
        },
        addNewField(arrayName) {
          this[arrayName].push({text: ''})
        },
        saveFields(arrayName){
            let i
            let errIndex = []
            if(this[arrayName].length === 1){
                for (i=0; i < this[arrayName].length; i++){
                if(this[arrayName][i].text === ""){
                alert("pls enter a value for field" + " " + (i+=1))
                    } else {
                      this.e6 += 1;
                    }
                }
            } else if(this[arrayName].length > 1) {
                for (i=0; i < this[arrayName].length; i++){
                    if(this[arrayName][i].text === ""){
                        errIndex.push(i)    
                    } 
                } if(errIndex.length > 0) {
                    for(i=0; i < errIndex.length; i++){
                        alert("please enter a value for field " + (errIndex[i]+=1))
                    } 
                } else {
                      this.e6 += 1;
                }
            
            }
        },
        findId(id, arr) {
            let find = this[arr].find(e => {
              return e['.key'] === this[id]
            })
            return find
        },

        findCascadeId(cid, arr) {
           let find = this[arr].find(e => {
              return e.id === this[cid]
            })
            return find
        },

        mapInput(arr){
          let values = this[arr].map(function(value) {
                return value['text']
            });
            return values;
        },

         mapInputArray(arr){
         let i
         let mapThis = []
         for(i=0; i < arr.length; i++) {
           mapThis.push({text: arr[i]})
         }
         return mapThis 
        },

        mapLikelihood() {
            let arr = this.LikelihoodLevel
          if(this.selectedLikelihood === ''){
              return ''
            } else {
              let filter = arr.filter(e => {
              return e['.key'] == this.selectedLikelihood
            })      
              return {id:this.selectedLikelihood, Level:filter[0].Level}
            }
          },
          mapImpact() {
            let arr = this.RiskImpactLevel
          if(this.selectedImpactLevel === ''){
              return ''
            } else {
              let filter = arr.filter(e => {
              return e['.key'] == this.selectedImpactLevel
            })      
              return {id:this.selectedImpactLevel, Level:filter[0].Level}
            }
          },
          async saveORMP(){
            let self = this
            let data = {
            CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
            Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
            RiskClassification: this.selectedRiskClassification,
            RiskName: this.findCascadeId('selectedRisk', 'cascadeRiskName'),
            Objectives: this.mapInput('objectiveInputFields'),
            Causes: this.mapInput('causesInputFields'),
            Impacts: this.mapInput('impactsInputFields'),
            AffectedStakeholders: this.mapInput('selectedStakeholders'),
            LikelihoodLevel: this.mapLikelihood(),
            ImpactLevel: this.mapImpact(),
            ImpactClassification: this.selectedImpactClassification,
            ImpactCriteria: this.selectedImpactCriteria,
            RiskStrategy: this.findId('selectedRiskStrategy', 'RiskStrategy'),
            RiskActions: this.riskActions,
            RiskTreatmentOwner: this.mapInput('selectedriskTreatmentOwners'),
            Vulnerability: this.computeVulnerabilityMethod()
            }
            console.log(data)
            const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new ORMP?`,
                title: `Confirm New ORMP`
            })

            if(confirm){
                  self.$db.collection('ORMP').add(data)
                 .then(()=>{
                    self.$dialog.notify.success(`Added New Risk`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })      
                    self.multiDialog = false               
                 })
            }
        }
    },
    computed: {
      hello() {
        console.log('hello',this.selectedRiskClassification)
        return 'hi'
      },
       cascadeRiskName() {
            let arr = this.Risk
               if(this.selectedRiskClassification == ''){
              return []
            } else {
                let filter = arr.filter(e => {
               return e.RiskClassification['.key'] == this.selectedRiskClassification['.key']
            })
            return filter.map(e => {
              return {
                      id: e['.key'],
                      RiskName: e.RiskName  
                    }
              })
            }    
        },
         displayImpact() {
          let id = this.selectedImpactClassification
          let arr = this.RiskImpactLevel
            if(this.selectedImpactLevel === ''){
              return []
            } else {
              let filter = arr.filter(e => {
              return e['.key'] == this.selectedImpactLevel
            })      
              return filter[0][id]    
            }
        },
        mapCauses() {
          if(this.causesInputFields.length === 0){
            return []
          } else {
           let map = this.causesInputFields.map(function(currentValue, index){
              return {text:currentValue.text, value: index}
            })
            return map
          }
        },
        computeVulnerability() {
          let arr1 = this.LikelihoodLevel
          let arr2 = this.RiskImpactLevel
          let result
          
          let filter1 = arr1.filter(e => {
            return e['.key'] == this.selectedLikelihood
          })

          let filter2 = arr2.filter(e => {
            return e['.key'] == this.selectedImpactLevel
          })
            result = filter1[0].Level * filter2[0].Level
            return result
        },
     }
  }
</script>

<style scoped>

</style>